body {
    background: #f1f1f1;
}

@import url('https://fonts.cdnfonts.com/css/impact');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

/* === */

footer {
    background: #383D52;
    padding-top: 62px;
}

footer .top {}

footer .top h2 {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160.9%;
}

footer .top .first_part img {
    width: 277px;
}

footer .top .first_part ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
}

footer .top .first_part ul li a {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: justify;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    margin-bottom: 15px;
    color: #bdbdbd;
}

footer .top .first_part ul li a:hover {
    opacity: 0.8;
}

footer .top .first_part ul li a img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

footer .top .second_part {
    display: flex;
    flex-direction: column;
/*    align-items: center;*/
}

footer .top .second_part ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 25px;
}

footer .top .second_part ul li {
    color: #FFF;
    text-align: justify;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
/*    list-style: none;*/
}

footer .top .second_part ul li a {
    text-decoration: none;
    color: #bdbdbd;
}

footer .top .second_part ul li a:hover {
    opacity: 0.8;
}

footer .top .second_part ul li:last-child {
    margin-bottom: 0;
}

footer .top .third_part h2 {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160.9%;
}

footer .top .third_part p {
    color: #bdbdbd;
}

footer .top .third_part form input,
footer .top .third_part form textarea {
    border: 1px solid rgba(79, 81, 90, 0.30);
    background: #FFF;
    color: rgba(79, 81, 90, 0.60);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Oswald', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 161.4%;
    letter-spacing: 0.5px;
    width: 100%;
    padding: 12px 14px;
    margin-bottom: 11px;
}

footer .top .third_part form textarea {
    height: 82px;
}

footer .top .third_part form button {
    background: #FF6319;
    padding: 11px 0;
    width: 100%;
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 161.4%;
    letter-spacing: 0.5px;
    margin-bottom: 50px;
    border: 0;
}

footer .top .third_part form button:hover {
    opacity: 0.8;
}

footer hr {
    background: rgba(255, 255, 255, 0.20);
    height: 1px;
    opacity: 1;
    border-top: 0;
    margin: 0;
}

footer .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px 0;
}

footer .bottom p {
    color: rgba(255, 255, 255, 0.60);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Oswald', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0;
}

footer .bottom a {
    color: rgba(255, 255, 255, 0.60);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Oswald', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
}

footer .bottom a:hover {
    opacity: 0.8;
}

/* === */

@media screen and (max-width: 768px){

    footer {
        padding-top: 10px;
    }

    footer .top .first_part img {
        width: 100%;
    }

    footer .top .second_part ul {
/*        padding-left: 65px;*/
    }

    footer .top .first_part ul li a {
        font-size: 14px;
    }

    footer .top .third_part {
        margin-top: 30px;
    }

    footer .bottom {
        flex-direction: column;
    }
}