body {
    background: #f1f1f1;
}

@import url('https://fonts.cdnfonts.com/css/impact');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

/* ===  */

header{
    background: linear-gradient(to right, rgba(0, 0, 0, 86%), rgba(104, 104, 104, 25%)), url('../../images/hero_image.jpg') center no-repeat;
    background-size: cover;
}

header .fixed_header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999; /* Ensure it stays on top */

}

header .topbar {
    background: #212121;
}

header .topbar ul {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    gap: 20px;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 14px;
}

header .topbar ul li a{
    color: #898989;
    text-decoration: none;
}

header .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
/*    border-bottom: 2px solid white;*/
}

header .navbar .logo {
    display: flex;
    align-items: flex-end;
    color: white;
}

header .navbar img.logo {
    width: 170px;
}

header .navbar .buttons{
    display: flex;
    gap: 20px;
}

header .navbar .buttons a {
    float: left;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 17px;
    /* border-bottom: 3px solid transparent; */
    cursor: pointer;
}

header .navbar .buttons a:hover {
    opacity: 0.7;
}

header .navbar .mobileButtons{
    display: none;
}

header .navbar .mobileButtons i{
    color: white;
    font-size: 25px;
/*    filter: invert(1);*/
}

header .navbar .mobileButtons .overlay {
  height: 100%;
  width: 100%;
  display: block; /* Change from 'none' to 'block' */
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
}

header .navbar .mobileButtons .overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

header .navbar .mobileButtons .overlay .overlay-content .mobileServices{
    padding: 20px 0;
}

header .navbar .mobileButtons .overlay .overlay-content .mobileServices p{
    color: white;
    font-size: 24px;
    margin: 0;
}

header .navbar .mobileButtons .overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 30px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

header .navbar .mobileButtons .overlay a:hover, header .navbar .mobileButtons .overlay a:focus {
  color: #f1f1f1;
}

header .navbar .mobileButtons .overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

header .navbar .dropdown .dropbtn {
  font-size: 15px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  text-transform: uppercase;
}

header .navbar .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 20px 0;
    border-radius: 10px;
}

header .navbar .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    margin: 5px 3px;
    border-radius: 10px;
    background: #f1f1f1;
}

header .navbar .dropdown-content a:hover {
    opacity: 0.7;
    color: black;
}

header .navbar .dropdown:hover .dropdown-content {
  display: block;
}

header .bannerText{
    padding: 100px 0;
}

header .bannerText h1{
    font-family: 'Oswald', sans-serif;
    color: white;
    font-size: 100px;
    font-weight: 400;
        text-shadow: 0 0 3px black;
}

header .bannerText h2{
    font-family: 'Oswald', sans-serif;
    color: #ed6d34;
    font-size: 45px;
    font-weight: 200;
    margin-top: -10px;
        text-shadow: 0 0 3px black;
}

header .bannerText p {
    font-family: 'Oswald', sans-serif;
    font-weight: 200;
    margin-top: 15px;
    color: white;
    font-size: 20px;
        text-shadow: 0 0 3px black;
}

header .bannerText .buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}

header .bannerText .buttons button {
    background: #ff6319;
    color: white;
    padding: 8px 24px;
    border: none;
    border-radius: 5px;
}

header .bannerText .buttons a {
    color: #ff6319;
    text-decoration: none;
    cursor: pointer;
}

header .bannerText .buttons button:hover,
header .bannerText .buttons a:hover {
    opacity: 0.7;
}

/* === */

@media screen and (max-width: 768px){
    
    header .topbar ul {
        flex-direction: column-reverse;
        gap: 0px;
        align-items: center;
    }

    header .navbar .buttons{
        display: none;
    }

    header .navbar .mobileButtons{
        display: block;
    }

    header .bannerText h1 {
        font-size: 60px;
    }
}