body {
    background: #f1f1f1;
}

@import url('https://fonts.cdnfonts.com/css/impact');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

/* === */

main {

}

main .first {
    padding: 30px 0;
}

main .first_box {
    padding: 35px 0;
}

main .first_box h1 {
    font-weight: 200;
    text-align: center;
}

main .first_box h1 span {
    color: #ff6319;
}

main .first_box p {
    text-align: center;
}

main .second_box .box {
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

main .second_box .box h2 {
    font-size: 20px;
}

main .second_box .box p {
    font-size: 16px;
}

main .second_box .box img {
    width: 80px;
}

main .banner {
    background: #ee7640;
    padding: 20px 0;
    text-align: center;
}

main .banner h3 {
    color: white;
}

main .banner button {
    background: #e5e5e5;
    border: 0;
    padding: 7px 25px;
    border-radius: 4px;
    margin-top: 10px;
    color: #ee7640;
    text-transform: uppercase;
}

main .banner button:hover {
    opacity: 0.9;
}

main .clients .client_box {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 25px;
    border-right: 1px solid #DAE3F6;
    border-bottom: 1px solid #DAE3F6;
    height: 190px;
}

main .clients .client_box:nth-child(4n) {
    border-right: 0;
}

main .clients .client_box:nth-last-child(-n+3) {
    border-bottom: 0;
}

main .contact form {
    display: flex;
    flex-direction: column;
    width: 80%;
}

main .contact form input {
    width: 100%;
    margin-bottom: 10px;
    background: #f3f3f3;
    border: 1px solid #dfdfdf;
    padding: 5px 18px;
}

main .contact form textarea {
    min-height: 150px;
    max-height: 250px;
    background: #f3f3f3;
    border: 1px solid #dfdfdf;
}

main .contact ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
}

main .contact ul li a {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: justify;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    margin-bottom: 15px;
    color: #bdbdbd;
}

main .contact ul li a:hover {
    opacity: 0.8;
}

main .contact ul li a img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

main .open_positions {
    display: flex;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    margin-bottom: 30px;
    color: #636363;
}

main .position {
    display: flex;
    align-items: flex-start !important;
    padding: 25px 25px;
    background: #e9e9e9;
    border-radius: 15px;
    margin-bottom: 25px;
    height: 90%;
}

main .position .date_location {
    display: flex;
    flex-direction: column;
}

main .position .job_description {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

main .position .job_description b {
    font-size: 14px;
    text-transform: uppercase;
    color: #636363;
}

main .position .job_description p{
    margin: 0;
    font-size: 14px;
    margin-top: 5px;
    padding-left: 10px;
    border-left: 2px solid #c4c4c4;
}

main .position .job_description p b {
    text-transform: none!important;
}